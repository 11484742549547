import type { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import { encodePassphrase, generateRoomId, randomString } from '../lib/client-utils';
import styles from '../styles/Home.module.css';

const Home = () => {
  const router = useRouter();
  function onTabSelected(index: number) {
    const tab = index === 1 ? 'custom' : 'demo';
    router.push({ query: { tab } });
  }
  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <h1>Camthis</h1>
          <h2>
            Live events streamed. Multi angle crowdsurfing.
          </h2>
        </div>
      </main>
      <footer data-lk-theme="default">
        A service by {' '}
        <a href="https://www.carmack.se/?ref=camthis" target="_blank">
          Carmack
        </a>
        .
      </footer>
    </>
  );
};

export default Home;
